.contact-section {
    .sec-title {
        font-size: 2.5rem;
    }

    ul {
        list-style: none;
        padding-inline-start: 0px;

        li {
            position: relative;
            padding-left: 28px;

            &::before {
                width: 20px;
                text-align: center;
                font-family: 'Font Awesome 6 Pro';
                font-weight: 400;
                font-size: 1.25rem;
                position: absolute;
                top: -2px;
                left: 0;
            }
        }
    }
}
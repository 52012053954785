@import 'assets/scss/variables.scss';

.content-page-section {
    h2 {
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
        margin-top: 2rem;
        margin-bottom: .75rem;
    }

    h4, h5 {
        font-family: 'Manrope', sans-serif;
        font-weight: 700;
    }
}
@import 'assets/scss/variables.scss';

.welcome-section {
    color: #FFF;
    position: relative;
    padding-top: 15rem;
    padding-bottom: 15rem;

    .header-content {
        position: relative;
        z-index: 2;
    }

    .header-bg {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .header-col-left {
        background-color: var(--bs-primary);

        @media (max-width: 767px) {
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: .85;
        }
    }

    .header-col-right {
        background-color: var(--bs-primary);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .welcome-title {
        font-size: 3.5rem;
        font-weight: 800;
        line-height: 1.3;
        margin-bottom: 1.5rem;

        @media (max-width: 767px) {
            font-size: 3rem;
        }
    }

    .welcome-description {
        font-size: 1.25rem;
        margin-bottom: 2.5rem;
    }
}

.story-section {
    p {
        text-align: justify;
    }
}

.menu-section {
    .menu-slider {
        .swiper-wrapper {
            align-items: center;
        }

        .swiper-slide {
            transition: width 200ms ease;
        }
        .swiper-slide-active {
            @media (min-width: 768px) {
                width: 260px !important;
            }

            .menu-item-img {
                box-shadow: 0px 12px 16px 8px rgba(16, 24, 40, 0.08);
            }
        }

        .menu-slide-item {
            width: 100%;
            // height: 100%;
            height: 355px;
            max-width: 320px;

            .menu-item-img-height {
                width: 100%;
                height: 100%;
                max-height: 320px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .menu-item-img {
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                border-radius: 2.5rem;
            }
        }
    }
}

.experience-section {
    @media (max-width: 767px) {
        background: rgba(204, 220, 255, 0.24);
    }
    .experience-section-wrapper {
        @media (min-width: 768px) {
            background: rgba(204, 220, 255, 0.24);
            border-radius: 2.375rem;
            padding: 5rem 2.5rem;
        }
    }

    .col-6 {
        @media (min-width: 768px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            &:not(:last-child) {
                border-right: 1px solid #CCDCFF;   
            }
        }
    }

    .experience-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1.5rem;
        font-size: 1.25rem;

        img {
            margin-right: 1rem;
            flex-shrink: 0;
        }
    }
}

.store-section {
    .store-card-wrapper {
        height: 100%;
        padding: 1.5rem;
        border-radius: 2.5rem;
        border-radius: 2.375rem;
        background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
    }
    .store-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        
        .store-card-img {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            flex-grow: 1;
            height: 100%;
            min-height: 400px;
            margin-bottom: 1.5rem;
            border-radius: 1.5rem;
        }

        .store-card-detail {
            h5 {
                margin-bottom: 1rem;
            }

            .store-location {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            p {
                font-size: 1.25rem;
                margin: 0;
            }
        }
    }

    .store-list {
        max-height: 636px;
        overflow-x: visible;
        overflow-y: scroll;
        overflow-y: auto;
    }

    .store-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.5rem;
        cursor: pointer;
        transition: border-color 350ms ease;
        border-radius: 2.375rem;
        border: 2px solid #FFF;
        background: #FFF;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);

        &:not(:last-child) {
            margin-bottom: 1.25rem;
        }

        &.active {
            border-color: var(--primary, #004FF9);
        }

        .store-item-img {
            flex-shrink: 0;
            width: 140px;
            height: 140px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 1.5rem;
            border-radius: 1.5rem;
        }

        h5 {
            margin-bottom: 1rem;
        }

        .store-location {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        p {
            font-size: 1.25rem;
            margin: 0;
        }
    }

    .store-list-slider {
    }
}

#map {
    position: relative;
}

.nft-maps {
    position: relative;
    height: 70vh;
    max-height: 600px;
}

.map-loading-indicator {
    display: none;
    position: absolute;
    z-index: 1020;
    bottom: 1.25rem;
    right: 1rem;
    padding: .25rem .5rem;
    color: #fff;
    background-color: var(--bs-primary);
    border-radius: .25rem;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .5);

    &.show {
        display: block;
    }
}

.map-style-selector {
    font-size: .875rem;
    position: absolute;
    z-index: 1020;
    bottom: 1rem;
    left: 1rem;

    .form-check {
        padding-left: 0;
        margin-bottom: 0;
    }

    .form-check-input:checked + .form-check-label {
        outline-color: var(--bs-primary);
    }

    .form-check-label {
        cursor: pointer;
        display: block;
        position: relative;
        border: 2px solid #fff;
        border-radius: .5rem;
        outline: 2px solid transparent;
        overflow: hidden;
        margin: 0;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, .5);
    }

    .map-style-img {
        width: 64px;
        height: 64px;

        @media (max-width: 768px) {
            width: 48px;
            height: 48px;
        }
    }

    .map-style-label {
        position: absolute;
        bottom: 6px;
        left: 6px;
        right: 6px;
        font-weight: 600;

        @media (max-width: 768px) {
            display: none;
        }
    }
}

.map-search-box {
    position: absolute;
    top: 1rem;
    left: calc(50% - 25vw);
    z-index: 1020;
    width: 50vw;
    border-radius: .25rem;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);

    @media (max-width: 768px) {
        left: calc(50% - 50vw + 1rem);
        width: calc(100vw - 2rem);
    }

    .form-control {
        background-color: #fff !important;
    }

    .map-search-results {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin-top: .25rem;
        border-radius: .25rem;
        max-height: calc(70vh - 8rem);
        overflow-x: hidden;
        overflow-y: scroll;
        overflow-y: auto;
        background-color: #fff;
        box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);

        &.show {
            display: block;
        }

        .map-search-result-item {
            cursor: pointer;
            padding: .5rem 1rem;
            text-transform: capitalize;

            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.25);
            }
        }
    }
}

.select-country-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;

    .select-country-item {
        &:not(:last-child) {
            margin-bottom: .75rem;
        }
        .btn {
            display: flex;
            width: 230px;
            margin: auto;

            .select-country-flag {
                width: 30px;
                height: 20px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                margin-right: 1rem;
            }
            .select-country-label {
                flex-grow: 1;
                text-align: center;
            }
        }
    }
}

.btn-select-country-on-maps {
    display: flex;
    position: absolute;
    z-index: 1020;
    bottom: 1.25rem;
    right: 1rem;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, .15);
    padding: .375rem .5rem;
    font-family: 'Manrope', sans-serif;
    font-size: .75rem;
    font-weight: 700;
    border-radius: .35rem;

    .select-country-flag {
        width: 30px;
        height: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: .5rem;
    }
    .select-country-label {
        flex-grow: 1;
        text-align: center;
    }
}